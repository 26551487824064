// src/components/Footer.tsx
import React from "react";
import "./Footer.css"; // Import your CSS file for styling

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          &copy; {new Date().getFullYear()} Megabites Ltd. All rights reserved.
        </p>
        {/* <ul className="footer-links">
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-of-service">Terms of Service</a>
          </li>
          <li>
            <a href="/contact">Contact Us</a>
          </li>
        </ul>*/}
        <div className="footer-contact">
          <p>
            Megabites 9a/b Oxgate Court Parade, Coles Green Road, Neasden,
            London, NW2 7ET <span>Call us on: 020 8208 3294</span>
          </p>
          <p>
            <a
              href="http://www.pavli.co.uk"
              target="_blank"
              className="chris-pavli"
            >
              Website by Chris Pavli
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
