// src/pages/Homepage.tsx
import React from "react";
import FullPageCarousel from "../components/FullPageCarousel/FullPageCarousel";

const Homepage: React.FC = () => {
  return (
    <div className="homepage">
      <FullPageCarousel />

      {/* Add more content for your homepage here */}
    </div>
  );
};

export default Homepage;
