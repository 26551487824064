import React, { useState } from "react";
import "./Gallery.css"; // Import CSS file for styling

const images = [
  "books1.jpg",
  "buffet-party-large.jpg",
  "buffet-party.jpg",
  "catering-function.jpg",
  "canapes.jpg",
  "contact.jpg",
  "counter-choices.jpg",
  "counter.jpg",
  "flat-bread-dips.jpg",
  "sandwiches-large.jpg",
  "sandwiches2.jpg",
  "table1.jpg",
  "tables.jpg",
  "wedding-buffet.jpg",
  "wedding-party-catering.jpg",
  "wedding-catering.jpg",
  "catering-function.jpg",
];

const Gallery: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="fixed-width">
      <h1>Gallery</h1>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={`/images/${image}`}
              alt={`Gallery item ${index + 1}`}
              className="gallery-image"
              onClick={() => handleImageClick(`/images/${image}`)}
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <img src={selectedImage} alt="Full view" className="modal-image" />
        </div>
      )}
    </div>
  );
};

export default Gallery;
