import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "./FullPageCarousel.css"; // Import your CSS for additional styling

const FullPageCarousel: React.FC = () => {
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  // Function to handle slide click
  const handleSlideClick = (path: string) => {
    console.log("Slide clicked!");
    navigate(path);
  };

  return (
    <Slider {...settings} className="full-page-carousel">
      <div
        className="carousel-slide"
        onClick={() => handleSlideClick("/catering-services")}
      >
        <div className="carousel-text">
          {/* <h2>Exceptional catering services for any event</h2>*/}
          <h2>Exceptional Catering</h2>
          <h3 className="hide-on-mobile">
            For all your events, whatever your requirements.
          </h3>
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/images/buffet-party-large.jpg`}
          alt="Megabites London - Catering for all events"
          className="carousel-image"
        />
      </div>
      <div className="carousel-slide" onClick={() => handleSlideClick("/menu")}>
        <div className="carousel-text">
          <h2>
            Explore our delicious menu
            <span className="hide-on-mobile"> with a variety of options.</span>
          </h2>
          <h3 className="hide-on-mobile">
            Our friendly staff are here to help
          </h3>
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/sandwiches-large.jpg`}
          alt="Delicious sandwiches availble in Megabites Sandwich bar London"
          className="carousel-image"
        />
      </div>
    </Slider>
  );
};

export default FullPageCarousel;
