import React from "react";
import "./AboutUs.css";

const AboutUs: React.FC = () => {
  return (
    <div>
      <div className="fixed-width">
        <h1>About Us</h1>
      </div>
      <div className="flex-container">
        <div className="flex-image">
          <img
            src={`${process.env.PUBLIC_URL}/images/contact.jpg`}
            alt="Megabites Catering for Professional, Corporate work events and Sandwich Bar"
            className="about-us-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/wedding-buffet.jpg`}
            alt="Contact Us"
            className="about-us-image hide-on-mobile"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/buffet-party-large.jpg`}
            alt="Contact Us"
            className="about-us-image hide-on-mobile"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/counter.jpg`}
            alt="Contact Us"
            className="about-us-image"
          />
        </div>
        <div className="flex-content">
          <h2>Delivering Excellence in Catering Since 1995</h2>
          <p>
            Established in 1995, we are a family-run business with a legacy of
            excellence, originally managed by mother and son duo, Helen and
            Alex. Today, while Helen has retired, Alex continues to lead the
            team, proudly serving the community from our shop at Staples Corner
            in northwest London.
          </p>
          <p>
            Our commitment to quality and service has earned us a reputation as
            a trusted provider of freshly made, hot and cold food, available
            both in-store and through our delivery service to local customers.
          </p>
          <p>
            In addition to our shop offerings, we specialise in catering for
            both commercial and private buffets, delivering delicious and
            meticulously prepared food for all your events.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
