// src/pages/Contact.tsx
import React from "react";
import "./Contact.css"; // Ensure this file has only unique styles

const Contact: React.FC = () => {
  return (
    <div>
      <div className="fixed-width">
        <h1>Contact Us</h1>
      </div>
      <div className="flex-container">
        <div className="flex-image flex-start ">
          <img
            src={`${process.env.PUBLIC_URL}/images/contact.jpg`}
            alt="Contact Us"
            className="contact-image"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/wedding-buffet.jpg`}
            alt="Contact Us"
            className="contact-image hide-on-mobile"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/buffet-party-large.jpg`}
            alt="Contact Us"
            className="contact-image hide-on-mobile"
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/counter.jpg`}
            alt="Contact Us"
            className="contact-image"
          />
        </div>
        <div className="flex-content">
          <div className="contact-info">
            <p>
              <strong>Megabites Sandwich bar</strong>
              <br />
              9A Oxgate Court Parade
              <br />
              Coles Green Road
              <br />
              London, NW2 7ET
              <br />
              <a
                href="https://www.google.com/maps/place/Megabites/@51.5669321,-0.2365093,17z/data=!3m2!4b1!5s0x487610fd39e13f93:0x907d9dc4dd781b0f!4m6!3m5!1s0x487610fd3658a8bf:0xff8fe14a3554187!8m2!3d51.5669288!4d-0.2339344!16s%2Fg%2F1v3hzgkv?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Map
              </a>
            </p>
            <p>
              Tel: <a href="tel:02082083294">020 8208 3294</a>
            </p>
          </div>
          <div className="contact-form">
            <form action="contact.php" method="post">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
