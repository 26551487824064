import React from "react";
import "./Reviews.css"; // Import CSS file for styling

const Reviews: React.FC = () => {
  return (
    <div className="reviews-page">
      <h1>Read Our Fantastic Reviews on Google</h1>
      <div className="reviews-content">
        <div className="rating">
          <img
            src="https://img.icons8.com/ios-filled/50/000000/star.png"
            alt="Star rating"
            className="star-icon"
          />
          <img
            src="https://img.icons8.com/ios-filled/50/000000/star.png"
            alt="Star rating"
            className="star-icon"
          />
          <img
            src="https://img.icons8.com/ios-filled/50/000000/star.png"
            alt="Star rating"
            className="star-icon"
          />
          <img
            src="https://img.icons8.com/ios-filled/50/000000/star.png"
            alt="Star rating"
            className="star-icon"
          />
          <img
            src="https://img.icons8.com/ios-filled/50/000000/star-half-empty.png"
            alt="Star rating"
            className="star-icon"
          />
          <span className="rating-text">4.6</span>
        </div>
        <p>
          We are proud of our fantastic reviews! Check out our Google Reviews
          and see why our customers love us.
        </p>
        <a
          href="https://www.google.com/maps/place/Megabites/@51.56701,-0.3137594,12z/data=!3m1!5s0x487610fd39e13f93:0x907d9dc4dd781b0f!4m10!1m2!2m1!1sMegabites!3m6!1s0x487610fd3658a8bf:0xff8fe14a3554187!8m2!3d51.5669288!4d-0.2339344!15sCglNZWdhYml0ZXNaCyIJbWVnYWJpdGVzkgENc2FuZHdpY2hfc2hvcOABAA!16s%2Fg%2F1v3hzgkv?entry=ttu"
          className="google-reviews-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Reviews on Google Maps
        </a>
      </div>
    </div>
  );
};

export default Reviews;
