import React from "react";
import "./CateringServices.css"; // Import component-specific styles if needed

const CateringServices: React.FC = () => {
  return (
    <div>
      <div className="fixed-width">
        <h1>Catering Services</h1>
      </div>

      <div className="flex-container">
        <div className="flex-image order-right">
          <img
            src={`${process.env.PUBLIC_URL}/images/wedding-party-catering.jpg`}
            alt="Megabites Wedding catering"
          />
        </div>
        <div className="flex-content">
          <h2>Weddings</h2>
          <p>
            Celebrate your special day with our wedding catering services. We
            offer a range of elegant and delicious options to make your wedding
            day memorable.
          </p>
        </div>
      </div>
      <div className="flex-container">
        <div className="flex-image">
          <img
            src={`${process.env.PUBLIC_URL}/images/canapes.jpg`}
            alt="Megabites Catering for Professional, Corporate work events"
          />
        </div>
        <div className="flex-content">
          <h2>Corporate Events</h2>
          <p>
            We provide exquisite catering services for professional events,
            ensuring a high-quality experience for your guests. From corporate
            meetings to conferences, our tailored menu options will suit your
            event needs.
          </p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-image order-right">
          <img
            src={`${process.env.PUBLIC_URL}/images/flat-bread-dips.jpg`}
            alt="Megabites Birthday parties catering and buffets"
          />
        </div>
        <div className="flex-content">
          <h2>Birthdays</h2>
          <p>
            Make your birthday celebrations special with our customised catering
            services. Whether it’s a small gathering or a grand party, we have
            the perfect menu for you.
          </p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-image">
          <img
            src={`${process.env.PUBLIC_URL}/images/baby-christening.jpg`}
            alt="Megabites Catering for Christenings and Baptisms"
          />
        </div>
        <div className="flex-content">
          <h2>Christenings</h2>
          <p>
            Our christening catering services provide a delightful spread to
            celebrate your little one’s special day with family and friends.
          </p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-image order-right">
          <img
            src={`${process.env.PUBLIC_URL}/images/funeral-catering.jpg`}
            alt="Megabites offers catering for funerals with care and sensitivity"
          />
        </div>
        <div className="flex-content">
          <h2>Funerals</h2>
          <p>
            We offer respectful and comforting catering services for funerals,
            ensuring that everything is handled with care and sensitivity.
          </p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-image">
          <img
            src={`${process.env.PUBLIC_URL}/images/wedding-buffet.jpg`}
            alt="Megabites offers catering and buffets for any event"
          />
        </div>
        <div className="flex-content">
          <h2>Buffets</h2>
          <p>
            Our buffet catering options are perfect for any gathering. Choose
            from a variety of dishes to create a spread that will impress your
            guests.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CateringServices;
