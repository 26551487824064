import "@fortawesome/fontawesome-free/css/all.min.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/global.css"; // Import global styles
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import CateringServices from "./pages/CateringServices"; // Import the new page
import Menu from "./pages/Menu"; // Import the new page
import MainLinks from "./components/MainLinks/MainLinks"; // Import MainLinks
import Contact from "./pages/Contact";
import Reviews from "./pages/Reviews"; // Import the Reviews page
import Gallery from "./pages/Gallery"; // Import the Gallery page

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/catering-services" element={<CateringServices />} />
            <Route path="/menu" element={<Menu />} /> {/* Add route */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/reviews" element={<Reviews />} />{" "}
            <Route path="/gallery" element={<Gallery />} />{" "}
            {/* Add route for Gallery */}
            {/* Add the reviews route */}
          </Routes>
        </main>
        <MainLinks /> {/* Add MainLinks here */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
