// src/components/MainLinks.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faConciergeBell,
  faCoffee,
  faImages,
  faStar,
  faFileContract,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./MainLinks.css";

const links = [
  { icon: faInfoCircle, label: "About Us", href: "/about" },
  {
    icon: faConciergeBell,
    label: "Catering Services",
    href: "/catering-services",
  },
  { icon: faCoffee, label: "Shop Menu", href: "/menu" },
  { icon: faImages, label: "Gallery", href: "/gallery" },
  { icon: faStar, label: "Reviews", href: "/reviews" },
  { icon: faEnvelope, label: "Contact Us", href: "/contact" },
  { icon: faPhone, label: "020 8208 3294", href: "tel:02082083294" },
];

const MainLinks: React.FC = () => {
  return (
    <div className="main-links">
      {links.map((link, index) => (
        <a key={index} href={link.href} className="link-item">
          <FontAwesomeIcon icon={link.icon} className="link-icon" />
          <span className="link-label">{link.label}</span>
        </a>
      ))}
    </div>
  );
};

export default MainLinks;
