// src/components/Navbar/Navbar.tsx

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link
import "./Navbar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Close the mobile menu when a link is clicked
  const handleLinkClick = () => {
    closeMobileMenu();
  };

  // Close the mobile menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const navbar = document.querySelector(".navbar");
      if (navbar && !navbar.contains(event.target as Node)) {
        closeMobileMenu();
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="branding">
          <Link className="logo" to="/" onClick={handleLinkClick}>
            About Us
            <img
              className="header-logo"
              src={`${process.env.PUBLIC_URL}/images/megabites-logo2.png`}
              alt="Megabites Sandwich bar"
            />
          </Link>
          <h1>
            Megabites{" "}
            <span>
              Sandwich Bar <br />
              Catering + Buffets
            </span>{" "}
            in North London, for any occasion, including parties, weddings,
            birthdays, funerals, christenings, bat mitzvah
          </h1>
        </div>
        <ul className={`nav-menu ${isMobileMenuOpen ? "active" : ""}`}>
          <li>
            <Link to="/about" onClick={handleLinkClick}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/catering-services" onClick={handleLinkClick}>
              Catering Services
            </Link>
          </li>
          <li>
            <Link to="/menu" onClick={handleLinkClick}>
              Shop Menu
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={handleLinkClick}>
              Gallery
            </Link>
          </li>
          <li>
            <Link to="/reviews" onClick={handleLinkClick}>
              Reviews
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
          <li>
            <a href="tel:02082083294">
              <i className="fas fa-phone-alt"></i> 020 8208 3294
            </a>
          </li>
        </ul>
        <div className="nav-right-side">
          <div className="mobile-contact-number">
            <a href="tel:02082083294">
              <i className="fas fa-phone-alt"></i>
            </a>
          </div>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
