// src/pages/Menu.tsx
import React from "react";
import "./Menu.css"; // Import CSS for styling
import { Link } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <div>
      <div className="fixed-width">
        <h1>Megabites Shop Menu</h1>
      </div>
      <div className="flex-container">
        <div className="flex-image">
          <a
            className="no-style-link"
            href="/Megabites-Menu.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/*<img src="https://picsum.photos/800/400?v2" alt="Visit our store" />*/}
            <img src="/images/sandwiches2.jpg" alt="Catering Services" />
          </a>
        </div>
        <div className="flex-content">
          <a
            className="no-style-link"
            href="/Megabites-Menu.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>Our Menu</h2>
          </a>
          <p>
            Visit us in our store to explore our delicious offerings. You can
            also download our menu directly from{" "}
            <a
              href="/Megabites-Menu.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {""}
              here
            </a>
            .
          </p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-image">
          <Link to="/catering-services" className="no-style-link">
            <img src="/images/wedding-buffet.jpg" alt="Catering Services" />
          </Link>
        </div>
        <div className="flex-content">
          <Link to="/catering-services" className="no-style-link">
            <h2>Looking for Catering Services?</h2>
          </Link>
          <p>
            We offer exceptional catering services for all types of events. {""}
            <Link to="/catering-services">Click here</Link> to learn more about
            our catering services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
